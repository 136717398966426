import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue'),
    meta:{
      title: '跳转配置'
    }
  },
  {
    path: '/Live/:Id',
    name: 'Live',
    component: () => import('../views/Live.vue'),
    meta:{
      title: '跳转中...'
    }
  },
  {
    path: '/:catchAll(.*)',
    name: '/404',
    meta:{
      title: '404错误页'
    },
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
